import cloud from './assets/Cloud_Upload.png';
import shield from './assets/Shield_Check.png';
import check from './assets/Octagon_Check.png';


const About = () => {
    return ( 
        <>
        <div className="my-16 px-4 md:px-16 lg:px-24 w-full font-Outfit flex flex-col justify-center items-center">
            <p className=" text-lg font-medium text-[#026ffa]">About us</p>
            <p className=" text-black font-semibold text-center mt-3 text-3xl">Why you Should Choose Recovrr?</p>
            <div className=" w-full grid grid-cols-1 md:grid-cols-3 mt-10 gap-[32px]">
                <div className="w-full p-6 border-[#acabab] border rounded-xl">
                    <button className=" w-10 h-10 rounded-[50%] flex justify-center items-center bg-[#026dfa36]">
                        <img src={ cloud } className=' w-7' alt="Recovrr allows users to upload data" />
                    </button> 
                    <p className=" text-black font-semibold leading-5 mt-4 text-base">Upload your locked wallet and guesses</p>
                    <p className=" text-[#4d4d4d] font-normal text-sm mt-2 leading-5">Our user dashboard allows you to upload data whenever you'd like to improve our odds of recovery. All data is encrypted in transit and at rest.</p>
                </div>

                <div className="w-full p-6 border-[#acabab] border rounded-xl">
                    <button className=" w-10 h-10 rounded-[50%] flex justify-center items-center bg-[#026dfa36]">
                        <img src={ shield } className=' w-7' alt="Recovrr secures your assets with an air gapped server" />
                    </button>
                    <p className=" text-black font-semibold leading-5 mt-4 text-base">Your wallet secured by an air-gap</p>
                    <p className=" text-[#4d4d4d] font-normal text-sm mt-2 leading-5">To ensure data integrity, your encrypted wallet backup is moved, off-line to one of our state of the art air-gaped servers.</p>
                </div>

                <div className="w-full p-6 border-[#acabab] border rounded-xl">
                    <button className=" w-10 h-10 rounded-[50%] flex justify-center items-center bg-[#026dfa36]">
                        <img src={ check } className=' w-7' alt="Recovrr helps you recover your lost crypto assets and password" />
                    </button>
                    <p className=" text-black font-semibold leading-5 mt-4 text-base">Your password recovered</p>
                    <p className=" text-[#4d4d4d] font-normal text-sm mt-2 leading-5">Our state of the art attack strategies for brute forcing wallet passwords have achieve roughly a 50% success rate.</p>
                </div>
            </div>
        </div>
        </>
     );
}
 
export default About;