import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import block from './assets/block.webp';
import beast from './assets/beast.webp';
import board from './assets/board.webp';
import bbc from './assets/bbc.webp';
import forbes from './assets/forbes.png';
import currency from './assets/currency.png';

const row1 = [block, beast, bbc, forbes, currency, board];

const Carousel = () => {
  return (
    <>
      <div className='my-16 px-4 md:px-16 lg:px-24 w-full font-Outfit'>
        <p className='text-[#000] font-semibold text-lg text-center'>Trusted by...</p>

        <AppContainer>
          <Wrapper>
            <Marquee>
              <MarqueeGroup>
                {row1.map((image, i) => (
                  <span key={i} className=' px-3 md:px-0 md:mx-6 md:w-auto'>
                    <img src={image} className=' w-[95px]' alt='' />
                  </span>
                ))}
              </MarqueeGroup>

              <MarqueeGroup>
                {row1.map((image, i) => (
                  <span key={i} className=' px-3 md:px-0 md:mx-6 md:w-auto'>
                    <img src={image} className=' w-[95px]' alt='' />
                  </span>
                ))}
              </MarqueeGroup>
            </Marquee>
          </Wrapper>
        </AppContainer>
      </div>
    </>
  );
};

export default Carousel;

const AppContainer = styled.div`
  width: 100%;
  color: #000000;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Marquee = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  user-select: none;
`;

const scrollX = keyframes`
  from {
    left: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

const common = css`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  white-space: nowrap;
 
  animation: ${scrollX} 30s linear infinite;
`;

const MarqueeGroup = styled.div`
  ${common}

  }
`;

const MarqueeGroup2 = styled.div`
  ${common}
  animation-direction: reverse;
  animation-delay: -3s;
`;

const ImageGroup = styled.div`
  display: grid;
  place-items: center;
`;

const Image = styled.img``;
