import waring from './assets/Circle_Warning.svg';
import scam from './assets/Shield_Warning.svg';
import laptop from './assets/Laptop.svg';
import insure from './assets/User_Card_ID.svg';

const Support = () => {
    return ( 
        <>
        <div className="my-16 px-4 md:px-16 lg:px-24 w-full font-Outfit flex flex-col justify-center items-center">
            <p className=" text-lg font-medium text-[#026ffa]">Support</p>
            <p className=" text-black font-semibold mt-3 text-[28px]">When we can help.</p>
            <p className=" text-[#4d4d4d] font-medium mt-3 text-lg">While not every lost wallet is recoverable, many of the most common issues are recoverable.</p>
            <div className=" w-full grid grid-cols-1 mt-10 md:grid-cols-2 gap-[32px]">
                <div className=" w-full p-6 flex flex-col justify-center items-center bg-[#026dfa36] rounded-xl">
                    <button className=" flex justify-center items-center w-10 h-10 rounded-[50%] bg-[#026ffa]">
                        <img className=' w-7 ' src={ waring } alt="Recovrr can help you recover lost password" />
                    </button>
                    <p className=" font-medium text-base text-black mt-6">Password doesn't work</p>
                    <p className=" font-normal text-sm text-[#000000a7] text-center mt-2">You thought you knew your password, but now it won't unlock your wallet. Perhaps it involves different numbers or different capitalization than you remember.</p>
                </div>
                
                <div className=" w-full p-6 flex flex-col justify-center items-center bg-[#026dfa36] rounded-xl">
                    <button className="flex justify-center items-center w-10 h-10 rounded-[50%] bg-[#026ffa]">
                        <img className=' w-7 ' src={ scam } alt="Recovrr can help you recover assets lost to scams" />
                    </button>
                    <p className=" font-medium text-base text-black mt-6">You've been scammed</p>
                    <p className=" font-normal text-sm text-[#000000a7] text-center mt-2">We offer a Tracing service (not a recovery service) for those looking to build their case and hopefully uncover the real-world identity of their scammer.</p>
                </div>

                <div className=" w-full p-6 flex flex-col justify-center items-center bg-[#026dfa36] rounded-xl">
                    <button className=" flex justify-center items-center w-10 h-10 rounded-[50%] bg-[#026ffa]">
                        <img className=' w-7 ' src={ laptop } alt="Recovrr can help you recover Seed Phrases" />
                    </button>
                    <p className=" font-medium text-base text-black mt-6">Partial seed phrase recovery</p>
                    <p className=" font-normal text-sm text-[#000000a7] text-center mt-2">You wrote down a list of words, and the passphrase is some combination of those words.</p>
                </div>

                <div className=" w-full p-6 flex flex-col justify-center items-center bg-[#026dfa36] rounded-xl">
                    <button className=" flex justify-center items-center w-10 h-10 rounded-[50%] bg-[#026ffa]">
                        <img className=' w-7 ' src={ insure } alt="Recovrr can insure you Assets Incase of any Mishaps" />
                    </button>
                    <p className=" font-medium text-base text-black mt-6">Insure your Crypto Assets</p>
                    <p className=" font-normal text-sm text-[#000000a7] text-center mt-2">You have lost access to a common crypto asset, such as Bitcoin, Ethereum, Litecoin, Dogecoin, an NFT or dozens of other alt coins</p>
                </div>
            </div>
        </div>
        </>
     );
}
 
export default Support;