import About from "../components/about";
import Ads from "../components/ad";
import Carousel from "../components/carousel";
import CarouselTwo from "../components/carouselTwo";
import Faq from "../components/faq";
import Footer from "../components/footer";
import Hero from "../components/hero";
import Navbar from "../components/navbar";
import Support from "../components/support";

const Landing = () => {
    return ( 
        <>
        <Navbar/>
        <Hero/>
        <Carousel/>
        <About/>
        <Ads/>
        <Support/>
        <CarouselTwo/>
        <Faq/>
        <Footer/>
        </>
     );
}
 
export default Landing;