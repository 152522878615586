import { useState } from "react";
import arrowDown from './assets/Caret_Down_MD.svg';



const Faq = () => {

    const faqsData = [
        {
          question: "How does it work?",
          answer:
            "The techniques we use to recover our clients’ accounts vary widely from case to case. In the event of a password recovery we will walk our clients through extracting an encrypted private key backup and building a comprehensive list of possible password guesses. We then transfer our client’s data to secure password cracking servers that use the data collected from the client to try billions or trillions of possible password combinations until the correct password is found",
        },
        {
          question: "Can i trust you?",
          answer:
            "Yes, We understand the issue of trust and authority is one that must be addressed before our clients feel comfortable sharing sensitive financial information with us. We are the only crypto asset recovery company who have been featured in multiple global media outlets such as the BBC, Business Insider, and VICE.Furthermore, Recovrr.net is a registered LLC in good standing in the state of New Hampshire.",
        },
        {
          question: "Are you legit - will you steal my funds?",
          answer:
            "We would never steal a client’s funds for any reason. We are a business based out of New Hampshire with public founders and a public business address, so if we were to steal from, or attempt to defraud our clients, it would be a simple process to report us which would quickly lead to our arrest and eventual jail time!",
        },
        {
            question: "Can you recover scammed or stolen assets?",
            answer:
              "Tracing and recovering stolen crypto is challenging but not impossible, Luckily we are one of the top services in that regard.",
        },
        
      ];
    
      const [openIndex, setOpenIndex] = useState(null);
    
      const toggleDropDown = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
      };

    return ( 
        <>
        <div className="my-16 px-4 md:px-16 lg:px-24 w-full font-Outfit flex flex-col justify-center items-center">
            <p className=" text-lg font-medium text-[#026ffa]">Support</p>
            <p className=" text-black font-semibold mt-3 text-3xl">Frequently asked questions</p>
            <p className=" text-[#4d4d4d] font-medium mt-3 mb-10 text-lg">Everything you need to know about the product.</p>

            {faqsData.map((faq, index) => (
                <div
                key={index}
                onClick={() => toggleDropDown(index)}
                className={`border-y py-6 w-full faq border-[#1E1E1E1A]${
                    openIndex === index ? ' active' : ''
                }`}
                >
                <div className="flex flex-row w-full justify-between items-center">
                    <p className="font-Outfit text-base md:text-lg font-normal">
                    {faq.question}
                    </p>
                    <img
                    src={arrowDown}
                    className={`${
                        openIndex === index ? 'transform rotate-180' : ''
                    } transition-transform duration-300`}
                    alt=""
                    />
                </div>
                <div className={`answer${openIndex === index ? ' open' : ''}`}>
                    <p className="font-Outfit font-normal text-[#4d4d4d] text-sm md:text-base mt-5 transition-all duration-500">
                    {faq.answer}
                    </p>
                </div>
                </div>
            ))}
        </div>
        </>
     );
}
 
export default Faq;