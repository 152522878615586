import { Link } from "react-router-dom";


const Footer = () => {
    return ( 
        <>
        <div className="mt-16 py-8 px-4 md:px-16 lg:px-24 w-full font-Outfit flex flex-col justify-center items-center bg-[#026ffa]">
            <div className=" w-full flex flex-col md:flex-row justify-between items-start md:items-center pb-6 border-b border-[#ffffffde]">
                <span className=" flex flex-col w-[300px]">
                    <p className=" font-Protest text-3xl text-[#ffffff]"><span className=" text-[#ea9054]">R</span>ecovrr</p>
                    <p className=" text-base text-[#ffffffde] mt-2 font-normal">The simplest way to recover your lost wallet password, recover lost assets and investigate scammed crypto.</p>
                </span>

                <div className="flex flex-row justify-center mt-6 md:mt-0 text-[#ffffffde] items-center space-x-8">
                    <Link to='/'><a href="" className=" font-Outfit font-medium text-base">Home</a></Link>
                    <Link to='/legal'><a href="" className=" font-Outfit font-medium text-base">Resources</a></Link>
                    <Link to='/trace-crypto'><a href="" className=" font-Outfit font-medium text-base">Scam Tracing</a></Link>
                </div>
            </div>
            <div className=" pt-6 md:pt-3 flex  justify-center items-center">
                <p className=" text-[#ffffffde]">© 2023 Recovrr.net, LLC. All rights reserved.</p>
            </div>
        </div>
        </>
     );
}
 
export default Footer;