import { Link } from 'react-router-dom';
import imhhero from './assets/pexels-rdne-stock-project-8370787.jpg';




const Hero = () => {
    return ( 
        <>
        <div className="md:px-16 px-4 w-full mt-28 md:mt-40 pb-16 font-Outfit">
            <div className=" flex flex-col w-full md:flex-row justify-center md:justify-between items-center">
                <div className="w-full md:w-[48%] text-center md:text-left">
                    <p className=" text-4xl md:text-6xl md:leading-[72px] font-semibold">Easy, Transparent Wallet Recovery</p>
                    <p className=" text-[#4d4d4d] mt-4 text-xl font-medium">The simplest way to recover your lost wallet password, recover lost assets and investigate scammed crypto.</p>
                    <span className=" w-full justify-center md:justify-start mt-8 flex flex-row items-center space-x-5">
                        <Link to='/contact'><button className=" text-[#4d4d4d] font-medium text-base px-4 py-2 hover:bg-[#026ffa] hover:text-white rounded-xl transition-all bg-transparent border border-[#4d4d4d] flex items-center justify-center text-center">Insure Wallet</button></Link>
                        <Link to='/contact'><button className=" text-white font-medium text-base px-4 py-2 rounded-xl bg-[#026ffa] hover:bg-transparent hover:border-[#4d4d4d] hover:text-[#4d4d4d] transition-all hover:border flex items-center justify-center text-center">Talk to an Expert</button></Link>
                    </span>
                </div>
                <div className="w-full md:w-[48%] mt-16 md:mt-0">
                    <div alt='Recovrr, a crypto recovery service' style={{backgroundImage:`url(${imhhero})`, backgroundPosition:'center', backgroundSize:'cover'}} className=" bg-[#026dfa6f] w-full h-[300px] rounded-xl"></div>
                </div>
            </div>
        </div>
        </>
     );
}
 
export default Hero;