import Footer from "../components/footer";
import Navbar from "../components/navbar";
import check from './assets/Check_All.png';
import tima from './assets/pexels-jopwell-2422293.jpg';
import search from './assets/Search_Magnifying_Glass.png';
import speed from './assets/Trending_Up.png';
import card from './assets/Credit_Card_01.png';
import load from './assets/load.gif';
import checked from './assets/check.png';
import warning from './assets/warning.png';
import { useRef, useState } from "react";


const Trace = () => {

    const scrollToRef = useRef(null);


    const handleScrollToRef = () => {
        if (scrollToRef.current) {
            window.scrollTo({
                top: scrollToRef.current.offsetTop,
                behavior: 'smooth'
            });
        }
    };

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [number, setNumber] = useState('');
    const [email, setEmail] = useState('');
    const [taken, settaken] = useState('');


    const [successMessage, setSuccessMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [loading, setLoading] = useState('No');
    const [formErrors, setFormErrors] = useState({
        firstName: '',
        lastName: '',
        number: '',
        email: '',
        taken: '',
    });

    const validateForm = () => {
        let isValid = true;

        const newErrors = {
            firstName: '',
            lastName: '',
            number: '',
            email: '',
            taken: '',
        };

        if (!firstName.trim()) {
            newErrors.firstName = 'First name is required';
            isValid = false;
        }

        if (!lastName.trim()) {
            newErrors.lastName = 'Last name is required';
            isValid = false;
        }

        if (!email.trim()) {
            newErrors.email = 'Email is required';
            isValid = false;
        } else if (!/^\S+@\S+\.\S+$/.test(email)) {
            newErrors.email = 'Invalid email format';
            isValid = false;
        }

        if (!number.trim()) {
            newErrors.number = 'Phone number is required';
            isValid = false;
        }


        if (!taken.trim()) {
            newErrors.taken = 'This is required';
            isValid = false;
        }

        setFormErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading('Yes');

        if (!validateForm()) {
            // Form validation failed
            setLoading('No');
            return;
        }
    
        // Construct the payload for Formspree
        const payload = {
          name: `${firstName} ${lastName} `,
          number: number,
          mail: email,
          taken: taken
        };
    
        try {
          // Make a POST request to the Formspree endpoint
          const response = await fetch('https://formspree.io/f/xdoqzjgp', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
          });
    
          if (response.ok) {
            // Handle successful form submission, e.g., show a success message
            setLoading('No');
            console.log('Form submitted successfully');
            setSuccessMessage(true);
          } else {
            setLoading('No');
            // Handle form submission error
            console.error('Form submission error');
            setErrorMessage(true);
          }
        } catch (error) {
          console.error('Error submitting form:', error);
          setLoading('No');
        }
    };


    return ( 
        <>
        <Navbar/>
        <div className="md:px-16 px-4 w-full mt-28 md:mt-40 pb-16 font-Outfit flex justify-center items-center flex-col">
            <div className=" flex flex-col w-full md:flex-row justify-center md:justify-between items-center">
                <div className="w-full md:w-[48%] text-center md:text-left">
                    <p className=" text-4xl md:text-6xl md:leading-[72px] font-semibold">Track down your scammed crypto</p>
                    <p className=" text-[#4d4d4d] mt-4 text-lg font-medium">We will trace your crypto on the blockchain, attempting to prove a link between the scammer, and a real world entity and possibly recover your scammed/stolen assets.</p>
                    <span className=" w-full justify-center md:justify-start mt-8 flex flex-row items-center">
                       <button onClick={handleScrollToRef} className=" text-white font-medium text-base px-6 py-4 rounded-xl bg-[#026ffa] hover:bg-transparent hover:border-[#4d4d4d] hover:text-[#4d4d4d] transition-all hover:border flex items-center justify-center text-center">Begin your free consultation</button>
                    </span>
                    <div className=" mt-6 space-y-3">
                        
                        <span className=" flex items-center space-x-3">
                            <img src={ check } alt="check" />
                            <p className=" text-[#4d4d4d] text-base font-normal">24 hr support response time</p>
                        </span>

                        <span className=" flex items-center space-x-3">
                            <img src={ check } alt="check" />
                            <p className=" text-[#4d4d4d] text-base font-normal">Personalized guidance on next recovery steps</p>
                        </span>

                        <span className=" flex items-center space-x-3">
                            <img src={ check } alt="check" />
                            <p className=" text-[#4d4d4d] text-base font-normal">Free consultation</p>
                        </span>
                    </div>
                </div>
                <div className="w-full h-full md:w-[48%] mt-16 md:mt-0">
                    <div alt='Recovrr, a crypto recovery service' style={{backgroundImage:`url(${tima})`, backgroundPosition:'center', backgroundSize:'cover'}} className=" bg-[#026dfa6f] w-full h-[430px] rounded-xl"></div>
                </div>
            </div>

            <div className=" w-full grid grid-cols-1 md:grid-cols-3 mt-16 gap-[32px]">
                <div className="w-full p-6 border-[#acabab] border rounded-xl">
                    <button className=" w-10 h-10 rounded-[50%] flex justify-center items-center bg-[#026dfa36]">
                        <img src={ search } className=' w-6' alt="Recovrr allows for higher solve rate" />
                    </button> 
                    <p className=" text-black font-semibold leading-5 mt-4 text-base">Higher Solve Rate</p>
                    <p className=" text-[#4d4d4d] font-normal text-sm mt-2 leading-5">Track cryptocurrency scams, disrupt criminal operations, and help law enforcement recover your funds.</p>
                </div>

                <div className="w-full p-6 border-[#acabab] border rounded-xl">
                    <button className=" w-10 h-10 rounded-[50%] flex justify-center items-center bg-[#026dfa36]">
                        <img src={ speed } className=' w-6' alt="Recovrr helps you Accelerate Your Case" />
                    </button>
                    <p className=" text-black font-semibold leading-5 mt-4 text-base">Accelerate Your Case</p>
                    <p className=" text-[#4d4d4d] font-normal text-sm mt-2 leading-5">Accelerate your investigation with visuals and data - proven blockchain analysis and extensive event reporting.</p>
                </div>

                <div className="w-full p-6 border-[#acabab] border rounded-xl">
                    <button className=" w-10 h-10 rounded-[50%] flex justify-center items-center bg-[#026dfa36]">
                        <img src={ card } className=' w-6' alt="Recovrr helps you recover your lost crypto assets" />
                    </button>
                    <p className=" text-black font-semibold leading-5 mt-4 text-base">Recover Your Assets</p>
                    <p className=" text-[#4d4d4d] font-normal text-sm mt-2 leading-5">Our state of the art tracing strategies helps us recover your assets have achieve roughly a 85% success rate.</p>
                </div>
            </div>

            <div ref={scrollToRef} className="mt-16 w-full text-black">
                <p className=" text-lg font-medium text-center text-[#026ffa]">Contact us</p>
                <p className=" text-black font-semibold text-center mt-3 text-3xl">Request Investigation Assistance</p>
                <div className=" mt-8">
                    <div className=" w-full p-3 md:p-8 md:border rounded-[8px] border-[#DAE0E6]">
                        <div className=" w-full flex flex-col md:flex-row justify-between mb-6">
                            <span className=" w-full md:w-[48%]">
                                <label className=" text-base font-medium" for="first-name">First name</label>
                                <input type="text" onInput={(e)=>{setFirstName(e.target.value)}} id="first-name" placeholder="John" class=" h-[45px] bg-transparent mt-2 border border-[#DAE0E6] text-black font-normal placeholder text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"/>
                                {formErrors.firstName && (
                                <p className="text-red-500 text-sm mt-1">{formErrors.firstName}</p>
                            )}
                            </span>
                            <span className=" w-full md:w-[48%]">
                                <label className=" text-base font-medium" for="first-name">Last name</label>
                                <input type="text" onInput={(e)=>{setLastName(e.target.value)}} id="mail" placeholder="Doe" class=" h-[45px] bg-transparent mt-2 border border-[#DAE0E6] text-black font-normal placeholder text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"/>
                                {formErrors.lastName && (
                                <p className="text-red-500 text-sm mt-1">{formErrors.lastName}</p>
                            )}
                            </span>
                        </div>
                        <div className=" w-full flex flex-col md:flex-row justify-between mb-6">
                            <span className=" w-full md:w-[48%]">
                                <label className=" text-base font-medium" for="first-name">Phone Number</label>
                                <input type="text" onInput={(e)=>{setNumber(e.target.value)}} id="first-name" placeholder="+1" class=" h-[45px] bg-transparent mt-2 border border-[#DAE0E6] text-black font-normal placeholder text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"/>
                                {formErrors.number && (
                                <p className="text-red-500 text-sm mt-1">{formErrors.number}</p>
                            )}
                            </span>
                            <span className=" w-full md:w-[48%]">
                                <label className=" text-base font-medium" for="first-name">Email Address</label>
                                <input type="text" onInput={(e)=>{setEmail(e.target.value)}} id="mail" placeholder="Johndoe@mail.com" class=" h-[45px] bg-transparent mt-2 border border-[#DAE0E6] text-black font-normal placeholder text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"/>
                                {formErrors.email && (
                                <p className="text-red-500 text-sm mt-1">{formErrors.email}</p>
                            )}
                            </span>
                        </div>

                        <label className=" text-base font-medium" for="first-name">How much was taken (in USD)</label>
                        <input type="number" onInput={(e)=>{settaken(e.target.value)}} placeholder="500"  class=" h-[45px] bg-transparent mt-2 border border-[#DAE0E6] mb-6 text-black font-normal placeholder text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"/>  
                        {formErrors.taken && (
                            <p className="text-red-500 text-sm mt-1">{formErrors.taken}</p>
                        )}                    

                        <button onClick={ handleSubmit } className=" w-full flex h-[45px] mt-6 rounded-[8px] bg-[#026ffa] hover:bg-opacity-75 transition-all justify-center items-center">
                            { loading === 'No' && <p className=" font-Outfit text-base text-white">Send Message</p> }
                            { loading === 'Yes' && <img src={ load } className=' w-6 h-6' alt="" />}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        { successMessage && <div className=" w-full h-[100vh] fixed top-0 left-0 bg-[#00000057] flex justify-center items-center z-[99999] px-5 md:px-0">
            <div className="w-full md:w-[450px] rounded-[30px] h-[250px] flex flex-col justify-center items-center bg-[#fff] relative">
                <img src={ checked } className=" w-[64px]" alt="" />
                <p className=" text-2xl font-medium font-Outfit text-[#000] mt-3">Thank You</p>
                <p className=" text-base font-normal font-Outfit text-[#4d4d4d]">Your Message has been submitted</p>
                <button onClick={()=>{setSuccessMessage(false)}} className=" bg-[#026ffa] mt-6 hover:bg-opacity-75 px-3 py-1 rounded-md text-white text-sm font-Outfit">Okay!</button>
            </div>
        </div>}

        { errorMessage && <div className=" w-full h-[100vh] fixed top-0 left-0 bg-[#00000057] flex justify-center items-center z-[99999] px-5 md:px-0">
            <div className="w-full md:w-[450px] rounded-[30px] h-[300px] flex text-center flex-col justify-center items-center bg-[#fff] relative">
                <img src={ warning } className=" w-[64px]" alt="" />
                <p className=" text-xl font-medium font-Outfit text-[#000] mt-3">There was an error submitting your Message.</p>
                <p className=" text-base font-normal font-Outfit text-[#4d4d4d]">Please try again</p>
                <button onClick={()=>{setErrorMessage(false)}} className=" bg-[#026ffa] mt-6 hover:bg-opacity-75 px-3 py-1 rounded-md text-white text-sm font-Outfit">Okay!</button>
            </div>
        </div>}

        <Footer/>
        </>
     );
}
 
export default Trace;
