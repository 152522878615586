import Landing from "./pages/landing";
import { Routes, Route, useLocation } from "react-router-dom";
import { useEffect } from "react";
import Testimonials from "./pages/testimonials";
import Contact from "./pages/contact";
import Legal from "./pages/legal";
import Privacy from "./pages/privacy";
import Terms from "./pages/terms";
import Trace from "./pages/trace";
import Dashboard from "./pages/dashboard";
import RecoverAssets from "./pages/recoverAssets";
import Payment from "./pages/payment";


function App() {

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);


  return (
    <>
    <Routes>
      <Route path='/' element={<Landing/>} />
      <Route path='/testimonials' element={<Testimonials/>} />
      <Route path='/contact' element={<Contact/>} />
      <Route path='/legal' element={<Legal/>} />
      <Route path='/Privacy-Policy' element={<Privacy/>} />
      <Route path='/Terms&Condition' element={<Terms/>} />
      <Route path='/trace-crypto' element={ <Trace/> } />
      <Route path="/insurewallet" element={ <Dashboard/>} />
      <Route path="/recoverassets" element={ <RecoverAssets/> }/>
      <Route path="/paywall" element={<Payment/>}  />
    </Routes>
    </>
  );
}

export default App;
