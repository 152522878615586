import Faq from "../components/faq";
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import load from './assets/load.gif';
import checked from './assets/check.png';
import warning from './assets/warning.png';
import { useState } from "react";

const Contact = () => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [number, setNumber] = useState('');
    const [email, setEmail] = useState('');
    const [howWeCanHelp, setHowWeCanHelp] = useState('');
    const [provider, setprovider] = useState('');
    const [walrth, setwalrth] = useState('');


    const [successMessage, setSuccessMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [loading, setLoading] = useState('No');
    const [formErrors, setFormErrors] = useState({
        firstName: '',
        lastName: '',
        number: '',
        email: '',
        howWeCanHelp: '',
        provider: '',
        walrth: '',
    });

    const validateForm = () => {
        let isValid = true;

        const newErrors = {
            firstName: '',
            lastName: '',
            number: '',
            email: '',
            howWeCanHelp: '',
            provider: '',
            walrth: '',
        };

        if (!firstName.trim()) {
            newErrors.firstName = 'First name is required';
            isValid = false;
        }

        if (!lastName.trim()) {
            newErrors.lastName = 'Last name is required';
            isValid = false;
        }

        if (!email.trim()) {
            newErrors.email = 'Email is required';
            isValid = false;
        } else if (!/^\S+@\S+\.\S+$/.test(email)) {
            newErrors.email = 'Invalid email format';
            isValid = false;
        }

        if (!number.trim()) {
            newErrors.number = 'Phone number is required';
            isValid = false;
        }

        if (!howWeCanHelp.trim()) {
            newErrors.howWeCanHelp = 'This is required';
            isValid = false;
        }

        if (!provider.trim()) {
            newErrors.provider = 'This is required';
            isValid = false;
        }

        if (!walrth.trim()) {
            newErrors.walrth = 'This is required';
            isValid = false;
        }

        setFormErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading('Yes');

        if (!validateForm()) {
            // Form validation failed
            setLoading('No');
            return;
        }
    
        // Construct the payload for Formspree
        const payload = {
          name: `${firstName} ${lastName} `,
          number: number,
          mail: email,
          What_they_want: howWeCanHelp,
          provider: provider,
          walrth: walrth
        };
    
        try {
          // Make a POST request to the Formspree endpoint
          const response = await fetch('https://formspree.io/f/mjvnrzwa', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
          });
    
          if (response.ok) {
            // Handle successful form submission, e.g., show a success message
            setLoading('No');
            console.log('Form submitted successfully');
            setSuccessMessage(true);
          } else {
            setLoading('No');
            // Handle form submission error
            console.error('Form submission error');
            setErrorMessage(true);
          }
        } catch (error) {
          console.error('Error submitting form:', error);
          setLoading('No');
        }
    };

    return ( 
        <>
        <Navbar/>
        <div className="md:px-16 px-4 w-full mt-28 md:mt-40 pb-16 font-Outfit flex justify-center items-center flex-col">
            <p className=" text-lg font-medium text-[#026ffa]">Contact us</p>
            <p className=" text-black font-semibold mt-3 text-[28px]">Talk to our support team</p>
            <p className=" text-[#4d4d4d] font-medium mt-3 md:px-[10%] text-lg text-center">Contact us to recover your stolen or scammed assets, the password or seed phrase to your locked crypto wallet. Our friendly team would love to hear from you.</p>

            <div className=" my-8 w-full md:px-10 lg:px-20 text-black">
                <div className=" w-full p-3 md:p-8 md:border rounded-[8px] border-[#DAE0E6]">
                    <div className=" w-full flex flex-col md:flex-row justify-between mb-6">
                        <span className=" w-full md:w-[48%]">
                            <label className=" text-base font-medium" for="first-name">First name</label>
                            <input type="text" id="first-name"  onInput={(e)=>{setFirstName(e.target.value)}} placeholder="John" class=" h-[45px] bg-transparent mt-2 border border-[#DAE0E6] text-black font-normal placeholder text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"/>
                            {formErrors.firstName && (
                                <p className="text-red-500 text-sm mt-1">{formErrors.firstName}</p>
                            )}
                        </span>
                        <span className=" w-full md:w-[48%]">
                            <label className=" text-base font-medium" for="first-name">Last name</label>
                            <input  onInput={(e)=>{setLastName(e.target.value)}} type="text" id="mail" placeholder="Doe" class=" h-[45px] bg-transparent mt-2 border border-[#DAE0E6] text-black font-normal placeholder text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"/>
                            {formErrors.lastName && (
                                <p className="text-red-500 text-sm mt-1">{formErrors.lastName}</p>
                            )}
                        </span>
                    </div>
                    <div className=" w-full flex flex-col md:flex-row justify-between mb-6">
                        <span className=" w-full md:w-[48%]">
                            <label className=" text-base font-medium" for="first-name">Phone Number</label>
                            <input type="text"  onInput={(e)=>{setNumber(e.target.value)}} id="first-name" placeholder="+1" class=" h-[45px] bg-transparent mt-2 border border-[#DAE0E6] text-black font-normal placeholder text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"/>
                            {formErrors.number && (
                                <p className="text-red-500 text-sm mt-1">{formErrors.number}</p>
                            )}
                        </span>
                        <span className=" w-full md:w-[48%]">
                            <label className=" text-base font-medium" for="first-name">Email Address</label>
                            <input  onInput={(e)=>{setEmail(e.target.value)}} type="text" id="mail" placeholder="Johndoe@mail.com" class=" h-[45px] bg-transparent mt-2 border border-[#DAE0E6] text-black font-normal placeholder text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"/>
                            {formErrors.email && (
                                <p className="text-red-500 text-sm mt-1">{formErrors.email}</p>
                            )}
                        </span>
                    </div>

                    <label className=" text-base font-medium" for="first-name">How can we help you?</label>
                    <select  onInput={(e)=>{setHowWeCanHelp(e.target.value)}} type="text" i class=" h-[45px] bg-transparent mt-2 border border-[#DAE0E6] mb-6 text-black font-normal placeholder text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                        <option value="">Select one</option>
                        <option value="Insure Wallet">Insure Wallet</option>
                        <option value="Scammed/Stolen Assets Recovery">Scammed/Stolen Assets Recovery</option>
                        <option value="Cross Chain Transaction">Cross Chain Transaction</option>
                        <option value="Other">Other</option>
                    </select>
                    {formErrors.howWeCanHelp && (
                        <p className="text-red-500 text-sm mt-1">{formErrors.howWeCanHelp}</p>
                    )}

                    <div className=" w-full flex flex-col md:flex-row justify-between mb-6">
                        <span className=" w-full md:w-[48%]">
                            <label className=" text-base font-medium" for="first-name">Wallet Provider</label>
                            <input type="text"  onInput={(e)=>{setprovider(e.target.value)}} id="first-name" placeholder="E.g Trust Wallet" class=" h-[45px] bg-transparent mt-2 border border-[#DAE0E6] text-black font-normal placeholder text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"/>
                            {formErrors.provider && (
                                <p className="text-red-500 text-sm mt-1">{formErrors.provider}</p>
                            )}
                        </span>
                        <span className=" w-full md:w-[48%]">
                            <label className=" text-base font-medium" for="first-name">Wallet Value</label>
                            <input type="text"  onInput={(e)=>{setwalrth(e.target.value)}} id="mail" placeholder="In USD($)" class=" h-[45px] bg-transparent mt-2 border border-[#DAE0E6] text-black font-normal placeholder text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"/>
                            {formErrors.walrth && (
                                <p className="text-red-500 text-sm mt-1">{formErrors.walletValue}</p>
                            )}
                        </span>
                    </div>

                    <button onClick={ handleSubmit } className=" w-full flex h-[45px] mt-6 rounded-[8px] bg-[#026ffa] hover:bg-opacity-75 transition-all justify-center items-center">
                        { loading === 'No' && <p className=" font-Outfit text-base text-white">Send Message</p> }
                        { loading === 'Yes' && <img src={ load } className=' w-6 h-6' alt="" />}
                    </button>
                </div>
            </div>

        </div>

        { successMessage && <div className=" w-full h-[100vh] fixed top-0 left-0 bg-[#00000057] flex justify-center items-center z-[99999] px-5 md:px-0">
            <div className="w-full md:w-[450px] rounded-[30px] h-[250px] flex flex-col justify-center items-center bg-[#fff] relative">
                <img src={ checked } className=" w-[64px]" alt="" />
                <p className=" text-2xl font-medium font-Outfit text-[#000] mt-3">Thank You</p>
                <p className=" text-base font-normal font-Outfit text-[#4d4d4d]">Your Message has been submitted</p>
                <button onClick={()=>{setSuccessMessage(false)}} className=" bg-[#026ffa] mt-6 hover:bg-opacity-75 px-3 py-1 rounded-md text-white text-sm font-Outfit">Okay!</button>
            </div>
        </div>}

        { errorMessage && <div className=" w-full h-[100vh] fixed top-0 left-0 bg-[#00000057] flex justify-center items-center z-[99999] px-5 md:px-0">
            <div className="w-full md:w-[450px] rounded-[30px] h-[300px] flex text-center flex-col justify-center items-center bg-[#fff] relative">
                <img src={ warning } className=" w-[64px]" alt="" />
                <p className=" text-xl font-medium font-Outfit text-[#000] mt-3">There was an error submitting your Message.</p>
                <p className=" text-base font-normal font-Outfit text-[#4d4d4d]">Please try again</p>
                <button onClick={()=>{setErrorMessage(false)}} className=" bg-[#026ffa] mt-6 hover:bg-opacity-75 px-3 py-1 rounded-md text-white text-sm font-Outfit">Okay!</button>
            </div>
        </div>}
        <Faq/>
        <Footer/>
        </>
     );
}
 
export default Contact;