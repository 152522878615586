import { useState } from 'react';
import lock from './assets/lock.png';
import loading from './assets/load.gif';
import copy from './assets/copy.png';
import check from './assets/check.png';
import { Link } from 'react-router-dom';

const Payment = () => {

    const [amountInt, setAmountInt] = useState(true);
    const [generating, setGenerating] = useState(false);
    const [walletInfo, setWalletInfo] = useState(false);
    const [done, setDone] = useState(false);

    const handleCopyClick = (e) =>{
        const parent = e.target.parentElement;
        const elements = parent.children;
        const addy = elements[0];
        addy.select();
        addy.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(addy.value);
    }

    const handleProceed = () => {
        const amountInput = document.querySelector('input[type="text"]');
        const amountValue = amountInput.value.trim();
    
        if (amountValue) {
            setAmountInt(false);
            setGenerating(true);
    
            // Simulate 8 seconds delay for generating
            setTimeout(() => {
                setGenerating(false);
                setWalletInfo(true);
            }, 8000);
        } else {
            // Optionally, you can provide feedback to the user that they need to input an amount.
            alert("Please enter an amount before proceeding.");
        }
    }
    

    const handlePaid = () => {
        setWalletInfo(false);
        setDone(true);

        // Simulate 5 seconds delay for generating
        setTimeout(() => {
            setDone(false);
            setAmountInt(true);
        }, 5000);
    }

    return ( 
        <>
        <div className=" relative mb-16">
            <div className=" fixed top-0 left-0 w-full px-4 bg-[#f5f5f4] shadow-sm z-[999] py-6 md:px-16 flex flex-col font-Outfit text-[#000000f4]">
                <span className=" flex w-full justify-between items-center">
                    <Link to='/'><p className=" font-Protest text-3xl text-[#026ffa]"><span className=" text-[#ea9054]">R</span>ecovrr</p></Link>
                    <p className=" font-medium text-[#4d4d4d] text-lg">Recovery Dashboard</p>
                </span>
            </div>

            <div className="mt-36 px-4 w-full justify-center items-center flex flex-col ">
                <p className=" text-2xl font-Outfit font-semibold">Payment</p>
                <p className=" text-center text-base font-Outfit text-[#4d4d4d] mt-3 px-[10%]">Follow the following steps to complete your payment</p>
                <div className=" w-full md:w-[520px] h-[370px] bg-[#f5f5f4] flex flex-col items-center justify-center mt-8 relative p-6 md:p-10 rounded-md">

                    {/* Enter amount */}
                    { amountInt &&
                    <div className=' w-full flex justify-center items-center flex-col'>
                        <p className=' text-lg font-semibold font-Outfit'>Enter Amount</p> 
                        <p className=' text-sm mt-3  font-Outfit text-[#4d4d4d]'>Please enter amount in USD($)</p>
                        <input placeholder='$0.00' className=' h-[45px] mt-8 bg-[#f5f5f5] border-b-2 border-[#000] placeholder:text-center font-Outfit p-2.5 text-center text-base w-full' type="text" />
                        <button onClick={ handleProceed } className=' mt-6 bg-[#000] rounded-md w-full text-white font-Outfit font-semibold text-base h-[45px] text-center'>Proceed</button>
                        <p className=' text-xs text-[#4d4d4d] font-light font-Outfit mt-3'>By Proceeding you agree to our<span className='text-[#026ffa]'> terms and condition</span></p>
                    </div>
                    }

                    {/* generating wallet */}
                    {generating &&
                        <div className=' w-full flex justify-center items-center flex-col'>
                            <img src={ loading } className=' w-7' alt="" />
                            <p className=' font-medium mt-3 text-sm font-Outfit'>Generating Address, Please Wait...</p>
                        </div>
                    }

                    {/* address and network */}
                    {walletInfo &&
                        <div className=' w-full'>
                            <p className=' text-center text-lg font-semibold font-Outfit'>Wallet Details</p> 
                            <p className=' text-center text-sm mt-3  font-Outfit mb-3 text-[#4d4d4d]'>Please copy the following details carefully</p>
                            <label className=' text-sm font-semibold font-Outfit relative w-full' htmlFor="">
                                Wallet Address (USDT)
                                <input value={'TT7HFj2q8TfBhNUqnpLUueHS4WZD4eRSm5'} className=' h-[30px] mt-2 font-normal text-sm mb-3 bg-[#f5f5f5] border-b-2 border-[#000] font-Outfit p-2.5 w-full' type="text" />
                                <img onClick={ handleCopyClick } src={ copy } className=' absolute right-0 top-[60%] w-5' alt="" />
                            </label>
                            <label className=' text-sm font-semibold font-Outfit' htmlFor="">
                                Network
                                <input value={'Tron (TRC 20)'} className=' h-[30px] mt-2 font-normal text-sm bg-[#f5f5f5] border-b-2 border-[#000] font-Outfit p-2.5 w-full' type="text" />
                            </label>
                            <button onClick={ handlePaid } className=' mt-6 bg-[#000] rounded-md w-full text-white font-Outfit font-semibold text-base h-[45px] text-center'>I have Paid!</button>

                        </div>
                    }

                    {/* transCTION COMPLETE */}
                    {done &&
                        <div className=' w-full flex justify-center items-center flex-col'>
                            <img src={ check } className=' w-' alt="" />
                            <p className=' font-medium mt-3 text-sm font-Outfit'>Transaction Complete</p>
                        </div>
                    }

                    
                    <span className=' flex flex-row space-x-1  absolute bottom-4 right-4 font-Outfit font-semibold text-xs'>
                        <img src={ lock } className=' w-3 h-3' alt="" />
                        <p className="">Secured by PCI DSS</p>
                    </span>
                </div>
            </div>
        </div>
        </>
     );
}
 
export default Payment;