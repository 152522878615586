import { Link } from "react-router-dom";
import Footer from "../components/footer";
import Navbar from "../components/navbar";

const Legal = () => {
    return ( 
        <>
        <Navbar/>
        <div className="md:px-16 px-4 w-full mt-28 md:mt-40 pb-16 font-Outfit flex justify-center items-center flex-col">
            <p className=" text-lg font-medium text-[#026ffa]">Legal</p>
            <p className=" text-black font-semibold mt-3 text-[28px]">Read through our legal docs</p>
            <p className=" text-[#4d4d4d] font-medium mt-3 text-lg text-center md:px-[15%]">To ensure that our clients are protected and informed, we provide a range of legal documents that outline our services, terms, and policies.</p>

            <div className=" w-full grid grid-cols-1 md:grid-cols-2 mt-10 gap-[32px]">
                <div className="w-full p-6 border-[#acabab] border rounded-xl ">
                    <button className=" w-10 h-10 rounded-[50%] flex justify-center items-center bg-[#026dfa36]">
                        {/* <img src={ cloud } className=' w-7' alt="Recovrr allows users to upload data" /> */}
                    </button> 
                    <p className=" text-black font-semibold leading-5 mt-4 text-base">Privacy policy</p>
                    <p className=" text-[#4d4d4d] font-normal text-sm mt-2 leading-5">All personal information is kept confidential and used only for the purposes for which it was provided.</p>
                    <Link to='/Privacy-Policy' className=" w-full"><button className=" text-sm rounded-xl bg-transparent mt-3 text-[#026ffa] font-medium">Read more</button></Link>
                </div>

                <div className="w-full p-6 border-[#acabab] border rounded-xl">
                    <button className=" w-10 h-10 rounded-[50%] flex justify-center items-center bg-[#026dfa36]">
                        {/* <img src={ shield } className=' w-7' alt="Recovrr secures your assets with an air gapped server" /> */}
                    </button>
                    <p className=" text-black font-semibold leading-5 mt-4 text-base">Terms and conditions
</p>
                    <p className=" text-[#4d4d4d] font-normal text-sm mt-2 leading-5">Our terms and conditions outline the rights and responsibilities of both Recovrr and our clients.</p>
                    <Link to='/Terms&Condition' className=" w-full"><button className=" text-sm rounded-xl bg-transparent mt-3 text-[#026ffa] font-medium">Read more</button></Link>
                </div>
            </div>
        </div>
        <Footer/>
        </>
     );
}
 
export default Legal;