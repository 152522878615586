import { Link } from 'react-router-dom';
import menu from './assets/Menu_Duo_LG.png';


const Navbar = () => {

    function overlay(){
        //check classlist
        const overlayDiv = document.getElementById('overlay');
        if(overlayDiv.classList.contains('-translate-y-[500px]')){
            overlayDiv.classList.remove('-translate-y-[500px]')
        }
        else if(!overlayDiv.classList.contains('-translate-y-[500px]')){
            overlayDiv.classList.add('-translate-y-[500px]')
        }
    };

    return ( 
        <>
        <div id="overlay" className=" w-full bg-[#fff] backdrop-blur-xl h-[300px] p-6 flex justify-center items-center -translate-y-[500px] shadow transition-all duration-700 top-[78px] fixed z-[99]">
            <div className="w-full flex flex-col justify-center items-center space-y-5">
                
                <Link to='/'><p className=" font-normal  transition-all cursor-pointer text-base text-[#000] font-Outfit ">Home</p></Link>
                <Link to='/contact'><p className=" font-normal  transition-all cursor-pointer text-base text-[#000] font-Outfit ">Insure</p></Link>
                <Link to='/legal'><p className=" font-normal  transition-all cursor-pointer text-base text-[#000] font-Outfit ">Legal</p></Link>
                <Link to='/trace-crypto'><p className=" font-normal  transition-all cursor-pointer text-base text-[#000] font-Outfit ">Scam Tracing</p></Link>
            </div>
        </div>

        <div className=" fixed top-0 z-[999] bg-[#fff] left-0 w-full py-6 px-4 md:px-16 flex justify-between items-center font-Outfit text-[#000000f4]">
            <p className=" font-Protest text-3xl text-[#026ffa]"><span className=" text-[#ea9054]">R</span>ecovrr</p>
            <div className=" hidden md:flex flex-row justify-center items-center space-x-8">
                <Link to='/'><a href="" className=" font-Outfit font-medium text-base">Home</a></Link>
                <Link to='/contact'><a href="" className=" font-Outfit font-medium text-base">Insure</a></Link>
                <Link to='/legal'><a href="" className=" font-Outfit font-medium text-base">Legal</a></Link>
                <Link to='/trace-crypto'><a href="" className=" font-Outfit font-medium text-base">Scam Tracing</a></Link>
            </div>
            <Link to='/contact'><button className=" hidden text-white font-medium text-base px-4 py-2 rounded-xl bg-[#026ffa] md:flex items-center justify-center text-center hover:bg-transparent hover:border-[#4d4d4d] hover:text-[#4d4d4d] transition-all hover:border">Get Started</button></Link>
            <div onClick={ overlay } className="menu-icon md:hidden">
                <input className="menu-icon__cheeckbox" type="checkbox" />
                <div>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
        </>
     );
}
 
export default Navbar;