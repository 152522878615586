import Ads from "../components/ad";
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import currency from './assets/currency.png';

const Testimonials = () => {
    return ( 
        <>
        <Navbar/>
        <div className="md:px-16 px-4 w-full mt-28 md:mt-40 pb-16 font-Outfit flex justify-center items-center flex-col">
            <p className=" text-lg text-center font-medium text-[#026ffa]">About Us</p>
            <p className=" text-black text-center font-semibold mt-3 text-3xl">Don’t just take our word for it</p>
            <p className=" text-[#4d4d4d] text-center font-medium mt-3 text-lg">Hear from some of our amazing customers who have been reunited with their funds.</p>

            <div className=" w-full grid grid-cols-1 md:grid-cols-2 mt-10 gap-[32px]">
                <div className="w-full p-6 border-[#acabab] border rounded-xl">
                    <button className=" w-10 h-10 rounded-[50%] flex justify-center items-center bg-[#026dfa36]">
                        {/* <img src={ cloud } className=' w-7' alt="Recovrr allows users to upload data" /> */}
                    </button> 
                    <p className=" text-[#4d4d4d] font-normal text-sm mt-4 leading-5">"Their top priority is keeping your information secure..."</p>
                    <p className=" text-black font-semibold leading-5 mt-2 text-base">Casey John</p>
                </div>

                <div className="w-full p-6 border-[#acabab] border rounded-xl">
                    <button className=" w-10 h-10 rounded-[50%] flex justify-center items-center bg-[#026dfa36]">
                        {/* <img src={ cloud } className=' w-7' alt="Recovrr allows users to upload data" /> */}
                    </button> 
                    <p className=" text-[#4d4d4d] font-normal text-sm mt-4 leading-5">"Chris recovering my assets after a good few weeks was too good to be true. He was patient and understanding...""</p>
                    <p className=" text-black font-semibold leading-5 mt-2 text-base">Miller</p>
                </div>

                <div className="w-full p-6 border-[#acabab] border rounded-xl">
                    <button className=" w-10 h-10 rounded-[50%] flex justify-center items-center bg-[#026dfa36]">
                        {/* <img src={ cloud } className=' w-7' alt="Recovrr allows users to upload data" /> */}
                    </button> 
                    <p className=" text-[#4d4d4d] font-normal text-sm mt-4 leading-5">""Great service, immediate reactions and I’ve got my password back (in 2 days!).""</p>
                    <p className=" text-black font-semibold leading-5 mt-2 text-base">Damon Teagues</p>
                </div>

                <div className="w-full p-6 border-[#acabab] border rounded-xl">
                    <button className=" w-10 h-10 rounded-[50%] flex justify-center items-center bg-[#026dfa36]">
                        {/* <img src={ cloud } className=' w-7' alt="Recovrr allows users to upload data" /> */}
                    </button> 
                    <p className=" text-[#4d4d4d] font-normal text-sm mt-4 leading-5">""Recovrr did an excellent job helping me recover my assets . I recommend 200% to anyone...""</p>
                    <p className=" text-black font-semibold leading-5 mt-2 text-base">Anderson</p>
                </div>

                
            </div>
            
           
            <div className=" mt-16 w-full flex justify-center flex-col items-center">
                <img src={ currency } className=" w-[250px]" alt="Currency.com" />
                <p className=" text-3xl text-center text-[#4d4d4d] mt-6">"Losing the password to your wallet can cost you millions, but options for recovery exist."</p>
                <p className="text-black font-medium mt-4 text-lg">William Farrington</p>
                <p className="text-[#4d4d4d] font-normal text-base">Journalist, Currency.com</p>
            </div>
            
        </div>
        <Ads/>
        <Footer/>
        </>
     );
}
 
export default Testimonials;