import { Link } from 'react-router-dom';
import archi from './assets/archi.svg';

const Ads = () => {
    return ( 
        <>
        <div className=" my-16 px-4 md:px-16 lg:px-24 w-full font-Outfit">
            <div className=" z-[99] h-[380px] md:h-auto py-10 px-8 bg-[#026ffa] rounded-2xl relative">
                <p className=" z-[99] font-semibold text-3xl text-white">Your keys, back in your pocket</p>
                <p className=" z-[99] text-[#f4f4f4dd] mt-4 font-medium text-lg">Put your finances under your control again. Click "Get started" to get help.</p>
                <span className=" z-[99] w-full justify-start mt-4 flex flex-row items-center space-x-5">
                    <Link to='/testimonials'><button className=" text-[#fff] font-medium text-base px-4 py-2 hover:text-[#000] rounded-xl transition-all bg-transparent border border-[#fff] flex items-center justify-center text-center">Testimonials</button></Link>
                    <Link to='/contact'><button className=" text-[#000] font-medium text-base px-4 py-2 rounded-xl bg-[#fff] hover:bg-opacity-95 hover:border-[#4d4d4d] hover:text-[#000] transition-all hover:border flex items-center justify-center text-center">Talk to Us</button></Link>
                </span>
                <img src={ archi } className=' -z-[1] absolute bottom-0 right-0 w-[50%] md:w-[25%]' alt="" />
            </div>
        </div>
        </>
     );
}
 
export default Ads;